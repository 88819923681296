import { useEffect }      from 'react'
import { useFetch }       from 'hooks/fetch/useFetch'
import { useSetAtom }     from 'state/jotai'
import { _bootstrap}      from 'state/store.bootstrap'
import { BOOT }           from 'api/api'
import { useTranslation } from 'react-i18next'
import { useBreak } from 'hooks/useBreak'

export const useFetch_Bootstrap = () => {
  const isMobile = useBreak('md_dn')
  const { i18n:{language} } = useTranslation()
  
  const { D:data } = useFetch({url:BOOT,file:'useFetch_Bootstrap.jsx'})
  const { Projects } = Object.keys(data || {}).length ? data : {};
  const state = Object.keys(data || {}).length ? true : false

  const projectLanguage =  data?.multiProjectOption ? [...new Set(data?.multiProjectOption[0]?.map( m => m.Language))] : [];

  const projectDropdown = {};
  projectLanguage.forEach(( lang ) => {
    projectDropdown[lang] = data.multiProjectOption[0].filter((m) => m.Language === lang).map((p, i) => {
      let projectData = Projects.filter((pr) => pr.ProjectID === p.PID)
      if(projectData.length > 0) return { ...p, PIN: i, available_Units: projectData[0]?.NoOfVacantUnits }
      return { ...p, PIN: i}
    } )
  })
  const updateAtom = useSetAtom(_bootstrap)

  useEffect(()=>{
    if ( data ) {
      updateAtom({
        // type         : data.HasMap ? 'poly' : 'mono',
        type         : data.HasMap ? 'mono' : 'poly',
        cdn          : data.CDNIsometry,
        openhouse    : data.ShowOpenHouseInfo,
        siteid       : data.SiteID,
        sitename     : data.SiteName,
        routes       : data.Router,
        startpage    : isMobile ? `${language}/list` : data.StartPage ?? `${language}/isometry`,
        dropdown     : data.Dropdown,
        projectids   : [data.ProjectIDs.join(','), ...[...data.ProjectIDs].reverse()],
        projectnames : { ...projectDropdown },
        languages    : projectLanguage
        // projectnames : [ 
        //   {"Presentation":"Isometry", "URLSafe":"Overview", "ProjectID":"4,1000002", path: '/isometry'},
        //   {"Presentation":"South" , "URLSafe":"South"  , "ProjectID": 4, path: '/isometry/South'},
        //   {"Presentation":"North" , "URLSafe":"North"  , "ProjectID": 1000002, path: '/isometry/North'}, 
        //  ]
      })
    }
  },[data])

  return state

}