import { active_project }         from 'state/store.global'
import { vis_filter, vis_detail } from 'state/store.global'
import { vis_desktop_nav }        from 'state/store.global'
import { useGet }                 from 'state/jotai'
import { Content, Root }          from './Layout.style'
import { DualMenu }               from 'components/DualMenu/DualMenu'
import { TopMenu }                from 'components/TopMenu/TopMenu'
import { useLocation }            from 'react-router-dom'
import { useBreak }               from 'hooks/useBreak'

export const Layout = ({ children }) => {
  
  const here = useLocation().pathname
  const isDesktop    = useBreak('md_up')

  const { Navigation, DrawersLeft, DrawersRight, Views } = children
  
  const pin   = useGet(active_project).pin
  const left  = useGet(vis_filter)
  const right = useGet(vis_detail)
  const nav   = useGet(vis_desktop_nav)
  const activeURL = ["list", "compare", "unit", "isometry", "gallery", "downloads", "favorites"]
  const activeURLindex = activeURL.findIndex(i => here.includes(i))
  
  const style = {
    "isometry": {
      marginTop: "59px",
      height: isDesktop ? "calc(100% - 59px)" : "calc(100% - 119px)"
    },
    "list": {
      marginTop: isDesktop ? "59px" : "119px",
      height: isDesktop ? "calc(100% - 59px)" : "calc(100% - 119px)"
    },
    "unit": {
      marginTop: "59px",
      height: isDesktop ? "calc(100% - 59px)" : "calc(100% - 119px)"
    },
    "compare": {
      marginTop: "59px",
      height: isDesktop ? "calc(100% - 120px)" : "calc(100% - 119px)"
    },
    "favorites": {
      marginTop: isDesktop ? "59px" : "119px",
      height: isDesktop ? "calc(100% - 120px)" : "calc(100% - 182px)"
    },
    "gallery": {
      marginTop: "59px",
      height: isDesktop ? "calc(100% - 120px)" : "calc(100% - 59px)"
    },
    "downloads": {
      marginTop: "59px",
      height: isDesktop ? "calc(100% - 120px)" : "calc(100% - 119px)"
    }
  }

  return (
    <Root>
      { pin >= 0 && DrawersLeft.map(i => i) }
      { pin >= 0 && DrawersRight.map(i => i) }
      <Content left={left} right={right} nav={nav} styles={style[activeURL[activeURLindex]]}>
        { Views }
      </Content>
      <TopMenu left={left} right={right} nav={nav}/>
      <DualMenu left={left} right={right} nav={nav}/>
      { Navigation }
    </Root>
  )
}