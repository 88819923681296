import styled from "@emotion/styled";
import { Box, IconButton, keyframes } from "@mui/material";
import useCompares from "components/Compare/useCompares";
import useFavorites from "components/Favorites/useFavorites";
import { IconBadge } from "components/Icons";
import LanguageMenu from "components/LanguageMenu/LanguageMenu";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toggle_drawer } from "state/actions";
import { useAtom } from "state/jotai";
import { useBreak }       from 'hooks/useBreak'
import { useLocation }    from 'react-router-dom'
import { _project } from "state/store.projects";
import { previousUrl, vis_desktop_nav }       from "state/store.global";

const swing = keyframes`
  20% { transform: rotate3d(0, 0, 1, 15deg); }
  40% { transform: rotate3d(0, 0, 1, -10deg); }
  60% { transform: rotate3d(0, 0, 1, 5deg); }
  80% { transform: rotate3d(0, 0, 1, -5deg); }
  to { transform: rotate3d(0, 0, 1, 0deg); }
`;

const SwingAnimation = styled.div(({ isAnimated }) => ({
  display: "inline-flex",
  transformOrigin: "top center",
  animationName: isAnimated ? swing : "none",
  animationDuration: "1s",
  animationIterationCount: "infinite",
}));

function NavItems({ hideNav }) {

  const isDesktop    = useBreak('md_up')
  const here         = useLocation().pathname
  const atUnit       = here.includes('unit')

  return (
    <Box sx={{ display: "flex", ml: "auto", pr: 1, borderRight: "1px solid rgba(0,0,0,0.15)", "& > .MuiIconButton-root, & > div > .MuiIconButton-root": { fontSize: 28, }, "& > div": { display: "flex", }, "& > .MuiIconButton-root, & > div": { mx: "4px", }, }} >
      {(!atUnit || isDesktop) && <NavCompares sx={{ visibility: hideNav ? "hidden" : "visible", }} />}
      {(!atUnit || isDesktop) && <NavFavorites sx={{ visibility: hideNav ? "hidden" : "visible", }} />}
      <LanguageMenu />
    </Box>
  );
}

NavItems.propTypes = { hideNav: PropTypes.bool, };

const Swing = forwardRef(({ children }, ref) => {

  const [isAnimated, setIsAnimated] = useState(false);
  const trigger = () => { setIsAnimated(true); };

  useEffect(() => {
    if (isAnimated) {
      const timeout = setTimeout(() => { setIsAnimated(false); }, 1000);
      return () => { clearTimeout(timeout); };
    }
  }, [isAnimated]);

  useImperativeHandle(ref, () => ({ trigger, }));

  return <SwingAnimation isAnimated={isAnimated}>{children}</SwingAnimation>;
});

Swing.displayName = "Swing";
Swing.propTypes = { children: PropTypes.node, };

const NavFavorites = (props) => {
  const { count } = useFavorites();
  const swingRef = useRef(null);
  const navigateTo = useNavigate();
  const toggleDrawer = useAtom(toggle_drawer)[1];
  const { i18n: { language }, } = useTranslation();
  const [show, setShow] = useAtom(vis_desktop_nav);
  const handleClick = () => { navigateTo(`${language}/favorites`); toggleDrawer(); setShow(false);};  useEffect(() => { if (swingRef.current) { swingRef.current.trigger(); } }, [count]);
  if (0 === count) { return <></>; }
  return (
    <IconButton {...props} onClick={handleClick}>
      <Swing ref={swingRef}>
        <IconBadge style={{color:clr?.sub_text_color}} icon="heart" badge={count} fontSize="inherit" />
      </Swing>
    </IconButton>
  );
};

const NavCompares = (props) => {
  const location = useLocation();
  const [, setPreviousUrl] = useAtom(previousUrl);

  const { count } = useCompares();
  const swingRef = useRef(null);
  const navigateTo = useNavigate();
  const toggleDrawer = useAtom(toggle_drawer)[1];
  const { i18n: { language }, } = useTranslation();
  const [show, setShow] = useAtom(vis_desktop_nav);
  const handleClick = () => { navigateTo(`${language}/compare`); toggleDrawer(); setShow(false); if(!location.pathname.includes("compare")) setPreviousUrl(location.pathname)};  useEffect(() => { if (swingRef.current) { swingRef.current.trigger(); } }, [count]);
  if (0 === count) { return <></>; }
  return (
    <IconButton {...props} onClick={handleClick}>
      <Swing ref={swingRef}>
        <IconBadge style={{color:clr?.sub_text_color}} icon="compare" badge={count} fontSize="inherit" />
      </Swing>
    </IconButton>
  );
};

export default NavItems;
